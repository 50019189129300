<template>
  <div class="dataTables" id="base-dataTables">
    <!--  自定义slots start-->
    <div class="datatable-toolbar" v-if="$slots.toolbar">
      <slot name="toolbar"></slot>
    </div>
    <a-table
      v-bind="{ ...$attrs, ...$props }"
      v-on="$listeners"
      class="table-content"
      :loading="isLoading"
      :row-key="rowKey"
      :dataSource="tableData"
      :pagination="false"
      tableLayout="fixed"
      bordered
      size="middle"
      :scroll="{ y: tableHeight }"
      ref="tableRef"
    >
      <a-table-column v-for="col in columnsData" v-bind="col" :key="col.dataIndex">
        <slot
          v-if="col['slots']"
          :record="record"
          :text="text"
          :index="index"
          :name="col['slots']['customRender']"
          slot-scope="text, record, index"
        >
          <template v-if="col.slotsType == 'format'">{{ col.slotsFunc(text) }}</template>
          <template v-if="col.slotsType == 'all'">{{ col.slotsFunc(record) }}</template>
        </slot>
        <template slot-scope="text, record" v-else>
          <span>{{ record[col['dataIndex']] }}</span>
        </template>
      </a-table-column>
    </a-table>
    <div class="absolute ml-3 mt-3">
      <slot name="footer"></slot>
    </div>
    <div v-if="total" class="pagination">
      <a-pagination
        size="small"
        :total="total"
        :current="page"
        :show-total="total => `共 ${total} 条`"
        showSizeChanger
        @change="onPageChange"
        @showSizeChange="sizeChange"
        :pageSize="pageSize"
      />
    </div>
  </div>
</template>
<script>
import { Table } from "ant-design-vue"
export default {
  extends: Table,
  name: "DataTables",
  props: {
    rowSelection: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: String,
      default: "id",
    },
    columnsData: {
      type: Array,
    },
    tableData: {
      type: Array,
    },
    getListFunc: {
      // 获取列表数据函数API
      type: Function,
    },
    total: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    customHeight: {
      type: Number,
    },
    addHeight:{
      type: Number,
      default: 0
    },
    noPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHeight: 500,
    }
  },
  mounted() {
    // var offsetHeight = window.innerHeight
    // this.tableHeight = offsetHeight - this.headerHeight

    this.$nextTick(() => {
      if (this.customHeight) {
        this.tableHeight = this.customHeight
      } else {
          this.tableHeight = this.getTableScroll()  
      }
    })

  },
  methods: {
    handleChange(value, key, column) {
      console.log(value, key, column)
    },
    onPageChange(e) {
      this.$emit("update:page", e)
      this.getListFunc()
    },
    sizeChange(_, pageSize) {
      this.$emit("update:page", 1)
      this.$emit("update:pageSize", pageSize)
      this.getListFunc()
    },
    getTableScroll(extraHeight, id) {
      if (typeof extraHeight == "undefined") {
        //  默认底部分页64 + 边距10
        extraHeight = (this.noPage ? 0 : 43) + this.addHeight
      }
      let tHeader = null
      if (id) {
        tHeader = document.getElementById(id)
          ? document
            .getElementById(id)
            .getElementsByClassName("ant-table-thead")[0]
          : null
      } else {
        tHeader = document.getElementsByClassName("ant-table-thead")[0]
      }
      //表格内容距离顶部的距离
      let tHeaderBottom = 0
      if (tHeader) {
        tHeaderBottom = tHeader.getBoundingClientRect().bottom
      }
      //窗体高度-表格内容顶部的高度-表格内容底部的高度
      // let height = document.body.clientHeight - tHeaderBottom - extraHeight
      let height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`
      return height
    },
  },
  watch: {
    isLoading() { },
  },
}
</script>

<style>
/* .ant-table-body{
  overflow: auto !important;
} */
.row-mine{
  background: #ffd7d7;
}
</style>
<style scoped>
.dataTables {
  background: #ffffff;
  height: 100%;
  overflow: hidden;
}
.datatable-toolbar {
  /* margin: 10px 10px 0; */
  margin-top: 10px;
}
.table-content {
  /* margin: 0 10px 0; */
  height: 100%;
  /* border-bottom: 1px solid #f0f0f0; */
  overflow: hidden;
}

.editable-row-operations a {
  margin-right: 8px;
}
.pagination {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #eee;
}

</style>