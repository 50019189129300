//引入axios
import axios from 'axios';
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { message } from 'ant-design-vue'
import store from '@/store'
import router from '@/router'
// let cancel, promiseArr = {};
// const CancelToken = axios.CancelToken;
import config from '@/utils/config'

// 创建axios实例
const service = axios.create({
  baseURL: config.BASE_URL,
  timeout: 10000, // 请求超时时间
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  }
})


let defaultParams = {
  platform: 'yght',
  ver: config.VERSION,
  token: localStorage.getItem(ACCESS_TOKEN) || ''
}


function checkToken () {
  if (localStorage.getItem(ACCESS_TOKEN)) {
    defaultParams['token'] = localStorage.getItem(ACCESS_TOKEN)
  }
}

// //请求拦截器
// service.interceptors.request.use(config => {
//   // 发起请求时，取消掉当前正在进行的相同请求
//   if (promiseArr[config.url]) {
//     promiseArr[config.url]('操作取消');
//     promiseArr[config.url] = cancel;
//   } else {
//     promiseArr[config.url] = cancel;
//   }
//   return config;
// }, error => {
//   return Promise.reject(error);
// });

//响应拦截器即异常处理
service.interceptors.response.use(
  (res) => {
    if (res && res.data) {
      switch (res.data.code) {
        case 1: //错误
          message.error(res.data.message)
          break;
        case 2: // 登录超时
        case 8: // 账号被其他人登录
          message.loading(res.data.message, 2).then(
              () => {
                console.log(router)
                store.dispatch('logout').then(() => {
                  router.push({
                    name: 'login',
                    query: {
                      redirect: location.pathname
                    }
                  }).finally(() => location.reload())
                })
              },
            )
          break;
        default:
      }
    }
    return res;
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = "错误请求";
          break;
        case 401:
          err.message = "未授权，请重新登录";
          break;
        case 403:
          err.message = "拒绝访问";
          break;
        case 505:
          err.message = "http版本不支持该请求";
          break;
        default:
          err.message = `连接错误${err.response.status}`;
      }
    } else {
      err.message = "连接到服务器失败";
    }
    return Promise.resolve(err.response);
  }
);

service.get = function (url, param) {
  checkToken()
  return new Promise((resolve, reject) => {
    service({
      method: "get",
      url,
      params: { ...param, ...defaultParams },
    }).then(res => {
      if (res && res.status && res.status === 0) {
        resolve(res.data);
      } else {
        message.error(res.message)
        reject(res);
      }
    }).catch(err => {
      reject(err);
    });
  });
},
  service.getBlob = function (url, param) {
    checkToken()
    return new Promise((resolve, reject) => {
      return service({
        url,
        method: 'GET',
        params: { ...param, ...defaultParams },
        responseType: 'blob',
      }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  },
  //post请求
  service.post = function (url, param) {
    return new Promise((resolve, reject) => {
      checkToken()
      service({
        method: "post",
        url,
        data: { ...param, ...defaultParams },
        
      }).then(res => {
        if(res){
          resolve(res.data);
        }else{
          message.error("网络错误")
          reject();
        }
      }).catch(err => {
        message.error(err.message)
        reject(err);
      });
    });
  };

service.openurl = function (url, param) {
  checkToken()

  let p = {
    ...param,
    ...defaultParams
  }
  let list = [] 
  for(let k in p){
    list.push(k+"="+encodeURIComponent(p[k]))
  }
  window.open(config.BASE_URL + url + (list.length ? "?"+list.join("&") : ""), "_blank")
}

export default service;
