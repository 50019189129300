import ajax from '@/utils/ajax.js'


/////////////////////outlets网点////////////////////
/**
 * 获取城市列表
 * @param {*} params 
 * @returns 
 */
export function getCityList(params) {
  return ajax.post('/Outlets/cityList',params)
}

/**
 * 获取财务城市列表
 * @param {*} params 
 * @returns 
 */
export function getFinanceCityList(params) {
  return ajax.post('/Outlets/financialCityList',params)
}


/**
 * 获取网点列表
 * @param {*} params 
 * @returns 
 */
 export function getOutletsList(params) {
  return ajax.post('/Outlets/getList',params)
}

/**
 * 获取网点详情
 * @param {*} params 
 * @returns 
 */
 export function getOutletsDetail(params) {
  return ajax.post('/Outlets/detail',params)
}


/**
 * 新建网点
 * @param {*} params 
 * @returns 
 */
 export function addOutlet(params) {
  return ajax.post('/Outlets/add',params)
}

/**
 * 修改网点
 * @param {*} params 
 * @returns 
 */
 export function updateOutlet(params) {
  return ajax.post('/Outlets/update',params)
}



/**
 * 修改网点
 * @param {*} params 
 * @returns 
 */
 export function updateStatus(params) {
  return ajax.post('/Outlets/updateStatus',params)
}


/**
 * 获取配送范围列表
 * @param {*} params 
 * @returns 
 */
 export function getRegionList(params) {
  return ajax.post('/OutletsDeliveryRegion/getList',params)
}


/**
 * 新增配送范围
 * @param {*} params 
 * @returns 
 */
 export function addRegion(params) {
  return ajax.post('/OutletsDeliveryRegion/add',params)
}



/**
 * 修改配送范围
 * @param {*} params 
 * @returns 
 */
 export function editRegion(params) {
  return ajax.post('/OutletsDeliveryRegion/update',params)
}



/**
 * 删除配送范围
 * @param {*} params 
 * @returns 
 */
 export function deleteRegion(params) {
  return ajax.post('/OutletsDeliveryRegion/updateStatus',params)
}

/**
 * 获取可关联的网点列表
 * @param {*} params 
 * @returns 
 */
 export function getRegionOutletsList(params) {
  return ajax.post('/OutletsDeliveryRegion/getOutletsList',params)
}



/////////////////////outlets网点创建订单////////////////////
/**
 * 获取网点列表
 * @param {*} params 
 * @returns 
 */
 export function getDeliveryOutletsList(params) {
  return ajax.post('/OutletsCreateOrder/deliveryCoOutletsList',params)
}

/**
 * 获取网点商品
 * @param {*} params 
 * @returns 
 */
 export function getGoodsListByOutletsId(params) {
  return ajax.post('/OutletsCreateOrder/getOutletsGoodsList',params)
}


/**
 * 订单价格计算
 * @param {*} params 
 * @returns 
 */
 export function getOrderPrice(params) {
  return ajax.post('/OutletsCreateOrder/priceCalculation',params)
}


/**
 * 订单价格计算
 * @param {*} params 
 * @returns 
 */
 export function createOrder(params) {
  return ajax.post('/OutletsCreateOrder/submit',params)
}

/**
 * 支付相关
 * @param {*} params 
 * @returns 
 */
 export function setOrderPayType(params) {
  return ajax.post('/OutletsOrderPay/pay',params)
}


// --------------------------------------------------------  门店通知
/**
 * 门店消息：新消息（轮训）
 * @param {*} params 
 * @returns 
 */
export function getNewNotice(params) {
  return ajax.post('/OutletsNotice/getNew',params)
}
/**
 * 门店消息：历史消息列表
 * @param {*} params 
 * @returns 
 */
export function getNoticeList(params) {
  return ajax.post('/OutletsNotice/getList',params)
}
