

export function isOrderNo (val) {
  // E2024032410102775
  return /^E\d{16}$/i.test(val || "") // E+16位数字
}

export function isSubOrderNo (val) {
  // SE101020204587
  // return /^S\d{12}$/i.test(val || "") // 13位长度
  return /^SE\d+$/i.test(val || "") // SE开头+13位数字
}