<template>
  <ALayoutHeader class="layout-header">
    <div class="left-options">
      <logo />
      <span class="menu-fold" @click="() => $emit('update:collapsed', !collapsed)">
        <a-icon :type="collapsed ? 'menu-fold' : 'menu-unfold'" />
      </span>
      <a href="https://blackswancake.com/" target="_blank" class="ml-2" style="color:white;">官网</a>
      <a :href="'/doc/env/'+(isTest?'#test':'')" target="_blank" class="ml-4" style="color:white;">软件</a>
      <router-link v-if="hasAuthMap" class="ml-4" style="color:#fff;"
        to="/outlets-delivery-map">配送地图</router-link>
    </div>
    <div class="center-options">
      <a-form-model>
      <div class="search-con flex">
        <a-input allowClear 
          enter-button
          v-model="search_order_no"
          placeholder="订单号/第三方订单号"></a-input>
        <a-button type="primary" html-type="submit" @click="gotoOrderDetail">查看订单</a-button>
      </div>
      </a-form-model>
    </div>
    <div class="right-options">
      <!-- <div v-for="item in iconList" :key="item.icon.name">
        <a-tooltip placement="bottom">
          <template #title>
            <span>{{ item.tips }}</span>
          </template>
          <component :is="item.icon" v-on="item.eventObject || {}" />
        </a-tooltip>
      </div> -->
      <div v-if="outletsInfo" style="margin-right:20px; cursor:default;">
        <span>{{outletsInfo.outlets_type==2?"配送站":"门店"}}：</span>
        <span class="mr-4">{{ outletsInfo.outlets_name }}</span>
      </div>
      
      <a-dropdown>
        <a style="color:#FFFFFF;">您好，{{ username }}</a>
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <a @click="handlerPerson">个人中心</a>
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item>
              <a @click.prevent="doLogout"> 退出登录</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>

    <a-modal title="网点订单消息列表"
             width="420px"
             :visible="isShowNotice"
             :footer="null"
             @cancel="isShowNotice = false">
      <div class="notice-list">
        <div>
          <a-tabs type="card" class="w-full"
                  @change="handlerTypeChange"
                  :activeKey="searchNoticeForm.type">
            <a-tab-pane key=""   tab="全部"></a-tab-pane>
            <a-tab-pane key="1"  tab="新单"></a-tab-pane>
            <a-tab-pane key="2"  tab="修改"></a-tab-pane>
            <a-tab-pane key="5"  tab="转出"></a-tab-pane>
            <a-tab-pane key="-1" tab="取消"></a-tab-pane>
          </a-tabs>
        </div>
        <div style="height:400px; overflow: auto;">
          <div v-for="item in noticeList"
                :value="item.id" :key="item.id"
                class="notice-item">
            <div>
              <template>
                <a-tag v-if="item.type==1" color="green">新单</a-tag>
                <a-tag v-if="item.type==2" color="grey">修改</a-tag>
                <a-tag v-if="item.type==5" color="red">转出</a-tag>
                <a-tag v-if="item.type==-1" color="red">取消</a-tag>
              </template>
              <span class="order" @click="openSubOrderDetail(item)">{{item.order_no}}</span>
            </div>
            <span class="time">{{item.add_time}}</span>
          </div>
          <div style="text-align:center;">
            <a-button v-if="noticeTotalCount > noticeList.length " 
                type="link" style="padding:0; margin:10px;"
                @click="loadMore">加载更多</a-button>
          </div>
        </div>
      </div>
    </a-modal>
    
  </ALayoutHeader>
</template>

<script>
import config from '@/utils/config.js'
import {message, Modal} from 'ant-design-vue'

import Logo from '../logo/index.vue'
import {
  isOrderNo,
} from '@/utils/core'
import { 
  getNewNotice, 
  getNoticeList 
} from '@/api/outlets'
import { 
  // checkAuth,
  checkPage
} from '@/utils/authData.js'

export default {
  name: "PageHeader",
  components: {
    Logo,
  },
  props: {
    collapsed: {
      type: Boolean,
    }
  },
  data(){
    return {
      isTest: config.ENV_TEST,
      hasAuthMap: checkPage('/outlets-delivery-area'),
      hasAuthAudit: checkPage('/order-cake'),

      search_order_no: "",

      iconList: [
        {
          icon:  'SettingOutlined',
          tips: '网站设置'
        },
        {
          icon: 'LockOutlined',
          tips: '锁屏',
          eventObject: {
            click: () => this.$store.commit('lockscreen/setLock', true)
          }
        },
      ],
      username: this.$store.getters['name'],
      outletsInfo: null,
      fullscreenIcon: 'FullscreenOutlined',

      isShowNotice: false,
      noticeList: [],
      noticeTotalCount: 0,
      searchNoticeForm: {
        type: "",
        page: 1,
        page_count: 20,
      },

      isPullNews: false,
      newCounts: 0,
      newPush: {
        reqId: 0,
        reqTime: 0,
        resList: [],
      },
      newPushReadDur: 10, 
      newPushReqDur: 60,
    }
  },
  mounted(){
    // 显示网点信息
    try{
      let outletsInfo = JSON.parse(localStorage.getItem('outletsInfo')||"")
      if(outletsInfo && outletsInfo.outlets_id){
        this.outletsInfo = outletsInfo
      }
    }catch(err){
      console.log(err)
    }

    // if(this.outletsInfo){
    //   try{
    //     this.isPullNews = localStorage.getItem('outlets_notice_switch')||""
    //   }catch(err){
    //     console.log(err)
    //   }
    //   // debug
    //   // this.isPullNews = 1

    //   this.initNewNotice()
    // }

    // 监听全屏切换事件
    document.addEventListener("fullscreenchange", this.toggleFullscreenIcon)
  },
  methods: {
    gotoOrderDetail(){
      let search_order_no = (this.search_order_no || "").replace(/^\s*/,"").replace(/\s*$/,"")
      if(!search_order_no){
        return
      }
      if(isOrderNo(search_order_no)){
        if(this.hasAuthAudit){
          const newPage = this.$router.resolve({
            name: "orderAudit",
            query: { 
              order_no: search_order_no,
            },
          });
          window.open(newPage.href, "_blank");
        }else{
          const newPage = this.$router.resolve({
            name: "order-detail",
            query: { 
              order_no: search_order_no,
              show: 1,
            },
          });
          window.open(newPage.href, "_blank");
        } 
      }else{
      // 第三方订单
        const newPage = this.$router.resolve({
          name: "otherPlatOrderDetail",
          query: { 
            other_order_no: search_order_no,
          },
        });
        window.open(newPage.href, "_blank");
      } 
    },
    showNoticeList(){
      this.handlerTypeChange(this.searchNoticeForm.type)
      this.isShowNotice = true
      this.newCounts = 0
      localStorage.setItem("outlets_notice_newcount", 0)
    },
    handlerTypeChange(type){
      this.searchNoticeForm.type = type
      this.searchNoticeForm.page = 1
      this.noticeTotalCount = 0
      this.noticeList = []
      this.initNoticeList()
    },
    async initNoticeList(){
      const {code, data} = await getNoticeList(this.searchNoticeForm)
      if(code == 0){
        this.noticeTotalCount = data.total_count
        this.noticeList = this.noticeList.concat(data.list)
      } 
    },
    loadMore(){
      this.searchNoticeForm.page = this.searchNoticeForm.page+1
      this.initNoticeList()
    },

    // 新消息
    initNewNotice(){
      // 处理缓存
      let cache
      try{
        cache = JSON.parse(localStorage.getItem("outlet_new_notice"))
      }catch(e){
        console.log("initNewNotice parse session err", e)
      }
      if(cache){
        if((new Date()).getTime() - cache.reqTime > 3600 * 1000){
        // 清理过期缓存：超一小时
          cache = null
          localStorage.removeItem("outlet_new_notice")
        }else{
        // 有效缓存
          this.newPush = cache
        }
      }
      // 从缓存读消息数
      if(cache){  
        this.newCounts = parseInt(localStorage.getItem("outlets_notice_newcount")) || 0
      }else{
        localStorage.removeItem("outlets_notice_newcount")
        this.newCounts = 0
      }
      // 启动轮训
      if(this.isPullNews){
        this.startReadNews()
      }
    },
    startReadNews(){
      if(!this.isPullNews) return
      const self = this

      setTimeout(function() {
        self.readNewsFormSession()
      }, this.newPushReadDur*1000)
    },
    async reqNews(){
      let cache = {
        reqId: Math.round(Math.random()*10000000),
        reqTime: (new Date()).getTime(),
        resList: [],
      }
      const { code, data } = await getNewNotice({
        reqId: cache.reqId
      })
      if(code == 0){
        cache.resList = data.list
        let count = parseInt(localStorage.getItem("outlets_notice_newcount")) || 0
            count = count + data.list.length
        this.newCounts = count
        localStorage.setItem("outlets_notice_newcount", count)
        localStorage.setItem("outlet_new_notice", JSON.stringify(cache))

        this.handleNews(cache, true)
      }
    },
    readNewsFormSession(){
      let cache
      try{
        cache = JSON.parse(localStorage.getItem("outlet_new_notice"))
      }catch(e){
        console.log("readNewsFormSession parse session err", e)
      }
      if(cache){
        // 没读取过
        if(this.newPush.reqId!=cache.reqId){ 
          this.handleNews(cache)
        }
      }
      
      // 从缓存读消息数
      this.newCounts = parseInt(localStorage.getItem("outlets_notice_newcount")) || 0
      
      if(this.isPullNews) {
        // 一会继续读
        this.startReadNews()
        // 看看是否需要请求
        let lastReqTime = cache && cache.reqTime || 0
        if((new Date()).getTime() - lastReqTime > this.newPushReqDur*1000){
          this.reqNews()
        }
      }
    },
    handleNews(cache){
      this.newPush = cache
      if(cache.resList && cache.resList.length){
        this.$message.info('订单通知：'+cache.resList.length+"  条订单消息", 5)
      }
    },

    openSubOrderDetail(item){
      if(item.type == -1 || item.type == 5){
        const newPage = this.$router.resolve({
          name: "order-detail",
          query: { 
            order_no: item.order_no,
            show: 1,
          },
        });
        window.open(newPage.href, "_blank");
      }else{
        const newPage = this.$router.resolve({
          name: "shop-order-detail",
          query: { order_no: item.suborder_no },
        });
        window.open(newPage.href, "_blank");
      } 
    },

    // 退出登录
    doLogout() {
      const _this = this
      Modal.confirm({
        title: '您确定要退出登录吗？',
        icon: '',
        onOk: () => {
          _this.$store.dispatch('logout').then(() => {
            message.success('成功退出登录')
          //   // 移除标签页
          //   // localStorage.removeItem()
            _this.$router.replace({
              name: 'login',
              query: {
                redirect: this.$route.fullPath
              }
            }).finally(() => location.reload())
          })
        }
      })
    },

    // 切换全屏图标
    toggleFullscreenIcon: () => (this.$state.fullscreenIcon = document.fullscreenElement !== null ? 'FullscreenExitOutlined' : 'FullscreenOutlined'),

    
    // 全屏切换
    toggleFullScreen: () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },

    handlerPerson(){
      this.$router.push({path: '/personal-center'})
    }
  }
}
</script>
<style>
  .search-con .ant-input-affix-wrapper input{
    border-radius: 20px 0 0 20px;
  }
  .search-con button{
    border-radius: 0 20px 20px 0;
  }
</style>
<style lang="less" scoped>
.layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #041527;
  padding: 0;
  height: 60px;

  .left-options {
    display: flex;
    align-items: center;

    .menu-fold {
      padding: 0 24px;
      cursor: pointer;
      color:#FFFFFF;
    }
  }

  .right-options {
    display: flex;
    align-items: center;

    > * {
      margin-right: 20px;
      cursor: pointer;
      color:#FFFFFF;
    }
  }
}
.ant-breadcrumb{
  color: #FFFFFF;
}
.ant-breadcrumb a{
  color: #FFFFFF;
}
.ant-breadcrumb > span:last-child a{
  color: #FFFFFF;
}


.notice-list{

}
.notice-list .small{
  height: 30px;
  line-height: 28px;
}
.notice-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  line-height: 20px;
  margin-right: 10px;
}
.notice-item .order{
  cursor: pointer;
}
.notice-item .order:hover{
  color: #1890ff;
}
.notice-item .time{
  font-size: 12px;
  color: #999;
}
.notice{
  cursor: pointer;
}
.num_bubble{
  font-size: 12px;
  color: #fff;
  display: inline-block;
  background: #f00;
  border-radius: 20px;
  line-height: 20px;
  height: 20px;
  padding: 0 5px;
}
</style>
