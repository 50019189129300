<template>
  <div class="main-content">
    <div>
      <!--      页头 start-->
      <page-header :collapsed.sync="collapsed" />
    </div>
    <div class="layout">
      <a-layout-sider
        :collapsed="collapsed"
        :trigger="null"
        collapsible
        class="layout-sider"
        :width="asiderWidth"
      >
        <!--      侧边菜单栏start-->
        <aside-menu :collapsed="collapsed" />
        <!--      侧边菜单栏end-->
      </a-layout-sider>
      <a-layout>
        <!--      内容区域start-->
        <a-layout-content class="layout-content">
          <tabs-view :asiderWidth="asiderWidth" />
          <!-- <div class="system-breadcrumb">
            <a-breadcrumb>
              <a-breadcrumb-item v-for="(routeItem) in $route.matched"
                    :key="routeItem.name">
                  {{ routeItem.meta.title }}
              </a-breadcrumb-item>
            </a-breadcrumb>
          </div>-->
          <div class="layout-content-main">
            <transition name="fade-transform" mode="out-in">
              <keep-alive>
                <router-view />
              </keep-alive>
            </transition>
          </div>
        </a-layout-content>
      </a-layout>
    </div>
  </div>
</template>

<script lang="ts">
import { Layout } from 'ant-design-vue'
import TabsView from './tabs/index.vue'
import AsideMenu from './menu/index.vue'
import PageHeader from './header/index.vue'

import store from '@/store/index'

export default {
  name: 'Layout',
  components: {
    TabsView,
    PageHeader,
    AsideMenu,
    [Layout.name]: Layout,
    [Layout.Content.name]: Layout.Content,
    [Layout.Sider.name]: Layout.Sider,
  },
  data() {
    return {
      collapsed: false,
    }
  },
  created() {
    // test 全局入口不知道在哪放
    let insession = sessionStorage.getItem("insession")
    if(insession != 1){
      this.checkLogin()
    }
  },
  computed: {
    asiderWidth() {
      return this.collapsed ? '80px' : '195px'
    }
  },
  methods: {
    async checkLogin(){
      let res = await store.dispatch('checkLogin').finally(() => {
        // this.loading = false
        // message.destroy()
      })
      if(res.code == 0){
        sessionStorage.setItem("insession", 1)
      }
    }
  },
  watch: {

  }
}
</script>

<style lang="less" scoped>
.main-content {
  overflow: hidden;
  height: 100vh;
}
.layout {
  display: flex;
  height: calc(100vh - 60px);
  .layout-sider {
    overflow: auto;
    background-color: #001529;
  }
  .layout-content {
    flex: none;
    .system-breadcrumb {
      box-sizing: border-box;
      margin: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1;
      list-style: none;
      font-feature-settings: "tnum", "tnum";
      position: relative;
      padding: 10px 24px;
      background-color: #fff;
      border-bottom: 1px solid #e9e9e9;
    }
    .layout-content-main {
      position: relative;
      padding: 6px 10px 0;
      background-color: #fff;
      height: calc(100vh - 102px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .tabs-view-content {
    padding: 20px;
  }
}
</style>
