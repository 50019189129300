import { login, checkLogin } from '@/api/user'
import { 
  ACCESS_TOKEN,
  CURRENT_USER,
  IS_LOCKSCREEN, 
  MENU_LIST,
  AUTH_DATA,
} from "@/store/mutation-types";
import store from '../index'

let currentUser = null
try{
  currentUser = JSON.parse(localStorage.getItem(CURRENT_USER))
}catch(err){
  console.log("JSON.parse CURRENT_USER", err)
}
if(!currentUser) currentUser = {}

const user = {
  // namespaced: true,
  state: {
    token: localStorage.getItem(ACCESS_TOKEN, ''),
    uid: currentUser.uid || '',
    name: currentUser.name || '',
    welcome: '',
    avatar: '',
    info: JSON.parse(localStorage.getItem(CURRENT_USER, {})),

    menuList: JSON.parse(localStorage.getItem(MENU_LIST, [])),
    authData: JSON.parse(localStorage.getItem(AUTH_DATA, {})),

    authCityList: JSON.parse(localStorage.getItem('cityList', [])),
    authAccountList: JSON.parse(localStorage.getItem('financial_account_list', [])),
  },

  getters: {
    token: (state) => state.token,
    avatar: (state) => state.avatar,
    uid: (state) => state.uid,
    name: (state) => state.name,
    userInfo: (state) => state.info,

    menuList: (state) => state.menuList,
    authData: (state) => state.authData,

    authCityList: (state) => state.authCityList,
    authAccountList: (state) => state.authAccountList,
  },

  mutations: {
    setToken: (state, token) => {
      state.token = token
    },
    setName: (state, info) => {
      state.name = info.name
    },
    setAvatar: (state, avatar) => {
      state.avatar = avatar
    },
    setUserInfo: (state, info) => {
      state.info = info
    },

    setMenuList: (state, menuList) => {
      state.menuList = menuList
    },
    setAuthData: (state, authData) => {
      state.authData = authData
    },

    setAuthCityList: (state, authCityList) => {
      state.authCityList = authCityList
    },
    setAuthAccountList: (state, authAccountList) => {
      state.authAccountList = authAccountList
    },
  },

  actions: {
    // 登录
    async login ({ commit }, userInfo) {
      try {
        const response = await login(userInfo)
        const { data, code } = response
        if (code == 0) {
          _initLoginData(commit, data)
        }
        return Promise.resolve(response)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // 打开页面 check登录态
    async checkLogin ({ commit }) {
      try {
        const response = await checkLogin()
        const { data, code } = response
        if (code == 0) {
          _initLoginData(commit, data)
        }
        return Promise.resolve(response)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // 登出
    async logout ({ commit }) {
      commit('setUserInfo', '')
      commit('setMenuList', [])
      commit('setAuthData', {})
      commit('setAuthCityList', [])
      commit('setAuthAccountList', [])
      
      localStorage.removeItem(ACCESS_TOKEN)
      localStorage.removeItem(CURRENT_USER)
      localStorage.removeItem(MENU_LIST)
      localStorage.removeItem(AUTH_DATA)
      localStorage.removeItem("cityList")
      localStorage.removeItem("financial_account_list")
      localStorage.removeItem("outletsInfo")
      localStorage.removeItem("outlet_new_notice")
      localStorage.removeItem("outlets_notice_newcount")

      return Promise.resolve('')
    }
  }
}

function _initLoginData(commit, data){
  const ex = 7 * 24 * 60 * 60 * 1000
  localStorage.setItem(ACCESS_TOKEN, data.token, ex)
  localStorage.setItem(CURRENT_USER, JSON.stringify({
    uid: data.uid,
    name: data.name
  }))
  localStorage.setItem(IS_LOCKSCREEN, false)
  // todo
  const menuList = data.menu_list.map((item,i)=>{
    item.key = i+1
    if(item.submenu_list.length>0){
      item.submenu_list.map((el,j)=>{el.key=item.key+'-'+(j+1); return el})
    }
    return item
  })
  const authData = {}
  data.auth_list.map((item)=>{
    authData[item.key] = true 
  })
  
  localStorage.setItem(MENU_LIST, JSON.stringify(menuList))
  localStorage.setItem(AUTH_DATA, JSON.stringify(authData))
  // 存储城市列表（区域管理）
  localStorage.setItem('cityList', JSON.stringify(data.outlets_city_list))
  // 存储财务账户（财务）
  localStorage.setItem('financial_account_list', JSON.stringify(data.financial_account_list))
  // 存储门店（门店管理）
  if(data.outlets_info && data.outlets_info.outlets_id){
    localStorage.setItem('outletsInfo', JSON.stringify(data.outlets_info))
  }else{
    localStorage.setItem('outletsInfo', JSON.stringify(""))
  }
  
  commit('setToken', data.token)
  commit('setUserInfo', data)
  commit('setName', data)
  commit('setMenuList', menuList)
  commit('setAuthData', authData)
  commit('setAuthCityList', data.outlets_city_list)
  commit('setAuthAccountList', data.financial_account_list)
  store.commit('lockscreen/setLock', false)
}

export default user
