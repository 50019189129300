
export const config = {

	ENV_TEST: false,

	VERSION: "1.0.7",

	HOST_IMG_URL: 'https://p1-static-blackswancake.1900m.com/',

	BASE_URL: 'https://api-admin-blackswancake.1900m.com',

	MAP_API_DOMAIN: 'https://apis.map.qq.com',
	// MAP_KEY: 'W3NBZ-SRBWU-VN4V2-B7O3L-B6ETT-O6FMG',
	MAP_KEY: 'CDRBZ-ZRW3Q-KK65D-GWOXN-GDRJH-ABBWI', // 注意 index.html里也有个key

	// 门店是否可以商品上下架
	SWITCH_SHOP_MANAGE_GOODS: false,
}


