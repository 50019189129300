<template>
  <a-menu :defaultOpenKeys="openKeys"
          :selectedKeys="selectedKeys"
          mode="inline"
          theme="dark"
          :inlineCollapsed="false"
          :inline-collapsed="collapsed"
          @click="clickMenuItem">
    <template v-for="item in filterMenuList">
      <menu-item v-if="item.submenu_list && item.submenu_list.length"
                 :key="item.key"
                 :menu-info="item">
      </menu-item>
      <a-menu-item v-else
                :key="item.path">
        <a-icon type="home" />
        <span>{{ item.title }}</span>
      </a-menu-item>
    </template>
  </a-menu>
</template>

<script>
import { Menu } from "ant-design-vue"
import { mapGetters } from "vuex"
const SubMenu = {
  template: `
      <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <a-icon type="appstore" /><span>{{ menuInfo.title }}</span>
        </span>
        <template v-for="item in menuInfo.submenu_list">
          <a-menu-item v-if="!item.submenu_list" :key="item.path">
            <span class="anticon">
              <!-- <a-icon type="profile" /> -->
              <span>{{ item.title }}</span>
            </span>
          </a-menu-item>
          <sub-menu v-else :key="item.path" :menu-info="item" />
        </template>
      </a-sub-menu>
    `,
  name: "SubMenu",
  // must add isSubMenu: true
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    // Cannot overlap with properties within Menu.SubMenu.props
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
}

// import { menu } from "@/utils/menu.js"
export default {
  name: "Menu",
  components: {
    "menu-item": SubMenu,
  },
  props: {
    collapsed: {
      // 侧边栏菜单是否收起
      type: Boolean,
    },
  },
  data () {
    return {
      openKeys: [1],
      selectedKeys: [],
      filterMenuList: [],
    }
  },
  computed: {
    ...mapGetters(["menuList"]),
  },

  created () {
    this.refreshMenu()
    
    this.selectedKeys = [this.$route.fullPath]
    this.openKeys.push(this.$route.fullPath)
  },
  methods: {
    // 点击菜单
    clickMenuItem ({ key }) {
      this.$router.push({ path: key })
      this.selectedKeys = [key]
    },

    refreshMenu(){
      // 读取前端配置router
      const routeHaxe = []
      this.$router.options.routes.map(mn=>{
        if(mn.path){
          routeHaxe.push(mn.path)
        }
        if(mn.children){
          mn.children.map(cmn=>{
            if(cmn.path){
              routeHaxe.push(cmn.path)
            }
          })
        } 
      })

      // 取前端router和服务端返回的权限列表的交集
      let filterMenuList = []
      this.menuList.map(menu=>{
        let menuItem = null
        // 如果父级菜单有交集、需要
        if(menu.path){
          if(routeHaxe.includes(menu.path)){
            menuItem = Object.assign({}, menu)
          }
        }
        
        // 如果子级别菜单有交集、需要
        let submenu_list = []
        if(menu.submenu_list && menu.submenu_list.length){
          menu.submenu_list.map(sub_menu=>{
            if(routeHaxe.includes(sub_menu.path)){
              submenu_list.push(sub_menu)
            }
          })
        }
        if(submenu_list.length){
          menuItem = Object.assign({}, menu, {
            submenu_list: submenu_list
          })
        }
        
        if(menuItem) filterMenuList.push(menuItem)
      })
      
      this.filterMenuList = filterMenuList
    },
  },
  watch: {
    $route (to) {
      this.selectedKeys = [to.fullPath]
      this.openKeys.push(to.fullPath)
    },
    menuList(){
      this.refreshMenu()
    },
  },
}
</script>
<style>
.ant-layout-sider {
  background: #ffffff;
}
</style>
