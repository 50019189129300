import Vue from "vue";
import Router from "vue-router";
import { ACCESS_TOKEN } from "@/store/mutation-types";

Vue.use(Router);

//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const loginRoutePath = "/login";
const defaultRoutePath = "/dashboard";
const whiteList = ["/login"]; // 不重定向白名单

/* Layout */
import Layout from "@/layout/index";
export const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "/dashboard",
    meta: {
      icon: "",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: "主页",
          icon: "icon-zhuomian",
        },
        component: () => import("@/pages/dashboard/index"),
      },
    ],
  },
  {
    name: "goods-store",
    path: "/goods-store",
    meta: {
      hidden: false,
      title: "商品管理",
      icon: "icon-shangpinguanli1",
    },
    component: Layout,
    children: [
      {
        path: "/goods",
        name: "goods",
        meta: {
          hidden: false,
          title: "商品库",
          icon: "icon-shangpin",
        },
        component: () => import("@/pages/goods/index"),
      },
      {
        path: "/goods-edit",
        name: "goods-edit",
        meta: {
          hidden: true,
          title: "商品详情",
          icon: "icon-zhuomian",
        },
        component: () => import("@/pages/goods/edit"),
      },
    ],
  },
  {
    path: "/outlets-manage",
    name: "outlets-manage",
    meta: {
      hidden: false,
      title: "门店管理",
      icon: "icon-mendian",
    },
    component: Layout,
    children: [
      {
        path: "/outlets",
        name: "outlets",
        meta: {
          hidden: false,
          title: "门店/配送站",
          icon: "icon-quyu",
        },
        component: () => import("@/pages/outlets/index"),
      },
      {
        path: "/outlets-edit",
        name: "outlets-edit",
        meta: {
          hidden: true,
          title: "网点编辑",
          icon: "icon-peisong",
        },
        component: () => import("@/pages/outlets/edit.vue"),
      },
      {
        path: "/outlets-delivery-area",
        name: "outlets-delivery-area",
        meta: {
          hidden: false,
          title: "配送范围",
          icon: "icon-quyu",
        },
        component: () => import("@/pages/outlets/delivery-area"),
      },
      {
        path: "/outlets-delivery-map",
        name: "outlets-delivery-map",
        meta: {
          hidden: false,
          title: "配送地图",
          icon: "icon-quyu",
        },
        component: () => import("@/pages/outlets/delivery-map"),
      },
      {
        path: "/outlets-area-config",
        name: "outlets-area-config",
        meta: {
          hidden: false,
          title: "售价区域模板",
          icon: "icon-quyu",
        },
        component: () => import("@/pages/outlets/area-config"),
      },
    ],
  },

  {
    path: "/shop",
    name: "shop",
    meta: {
      hidden: false,
      title: "门店经营系统",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      {
        path: "/shop-config",
        name: "shop-config",
        meta: {
          hidden: false,
          title: "门店配置",
          icon: "icon-cangku",
        },
        component: () => import("@/pages/shop/config/index"),
      },
      {
        path: "/current-stock",
        name: "current-stock",
        meta: {
          hidden: false,
          title: "当前库存",
          icon: "icon-cangku",
        },
        component: () => import("@/pages/shop/current-stock/index"),
      },
      {
        path: "/shop-sell-goods",
        name: "shop-sell-goods",
        meta: {
          hidden: false,
          title: "售卖商品",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/goods/index"),
      },
      {
        path: "/shop-online-order",
        name: "shop-online-order",
        meta: {
          hidden: false,
          title: "线上订单",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/order/index"),
      },
      {
        path: "/shop-order-detail",
        name: "shop-order-detail",
        meta: {
          hidden: false,
          title: "订单详情",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/order/order-detail"),
      },
      {
        path: "/shop-place-order",
        name: "shop-place-order",
        meta: {
          hidden: false,
          title: "本店下单",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/place-order"),
      },
      {
        path: "/shop-customer-refund",
        name: "shopRefund",
        meta: {
          hidden: false,
          title: "售后退款",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/refund"),
      },
      {
        path: "/shop-courier",
        name: "shop-courier",
        meta: {
          hidden: false,
          title: "配送员",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/courier"),
      },
      {
        path: "/shop-order-add",
        name: "shop-order-add",
        meta: {
          hidden: false,
          title: "新增订单",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/order/order-add"),
      },
      {
        path: "/shop-data-report",
        name: "shop-data-report",
        meta: {
          hidden: false,
          title: "产品销售数据",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/report/sales-report"),
      },
    ],
  },
  {
    path: "/goods-operation",
    name: "goods-operation",
    meta: {
      hidden: false,
      title: "商品运营",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      {
        path: "/shop-sell",
        name: "shop-sell",
        meta: {
          hidden: false,
          title: "小程序/网站在售",
          icon: "icon-quyu",
        },
        component: () => import("@/pages/shop/sell/index"),
      },
    ],
  },

  {
    path: "/activity-operation",
    name: "activity-operation",
    meta: {
      hidden: false,
      title: "活动运营",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      {
        path: "/advert-position",
        name: "advert-position",
        meta: {
          hidden: false,
          title: "小程序广告位",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/advert-position/index"),
      },
    ],
  },

  {
    path: "/finance",
    name: "finance-manage",
    meta: {
      hidden: false,
      title: "财务管理",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      {
        path: "/shop-financial-bill",
        name: "shop-financial-bill",
        meta: {
          hidden: false,
          title: "门店财务",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/finance/shop"),
      },
      {
        path: "/financial-account-bill",
        name: "financial-account-bill",
        meta: {
          hidden: false,
          title: "财务账户",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/finance/account"),
      },
      {
        path: "/financial-bill-export",
        name: "financial-bill-export",
        meta: {
          hidden: false,
          title: "财务账户明细导出",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/finance/accountexport"),
      },
      {
        path: "/regional-financial-bill",
        name: "/regional-financial-bill",
        meta: {
          hidden: false,
          title: "区域城市",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/finance/area"),
      },
      {
        path: "/hq-financial-bill",
        name: "/hq-financial-bill",
        meta: {
          hidden: false,
          title: "总部财务",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/finance/group"),
      },
    ],
  },
  {
    path: "/analysis",
    name: "analysis",
    meta: {
      hidden: false,
      title: "数据报表",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      {
        path: "/data-report",
        name: "data-report",
        meta: {
          hidden: false,
          title: "商品销售数据",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/analysis/sales-report"),
      },
      {
        path: "/export_data-report",
        name: "export_data-report",
        meta: {
          hidden: false,
          title: "城市订单导出",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/analysis/order-export"),
      },
      {
        path: "/export_data-report-financial",
        name: "export_data-report-financial",
        meta: {
          hidden: false,
          title: "财务订单导出",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/analysis/finance-order-export"),
      },
      {
        path: "/export_data-report-deliver",
        name: "export_data-report-deliver",
        meta: {
          hidden: false,
          title: "交付订单导出",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/analysis/sub-finance-order-export"),
      },
    ],
  },

  {
    path: "/trading-manage",
    name: "trading-manage",
    meta: {
      hidden: false,
      title: "交易管理",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      {
        path: "/refund",
        name: "refund",
        meta: {
          title: "售后退款",
        },
        component: () => import("@/pages/trading-manage/refund/index"),
      },
      {
        path: "/order-invoice",
        name: "order-invoice",
        meta: {
          title: "订单发票开具/上传",
        },
        component: () => import("@/pages/trading-manage/order-invoice/index"),
      },
      {
        path: "/topup-invoice",
        name: "topup-invoice",
        meta: {
          title: "充值发票开具/上传",
        },
        component: () => import("@/pages/trading-manage/topup-invoice/index"),
      },
      // {
      //   path: "/total-sell",
      //   name: "total-sell",
      //   meta: {
      //     title: "总交易流水",
      //   },
      //   component: () => import("@/pages/trading-manage/total-sell/index"),
      // },
      {
        path: "/account",
        name: "account",
        meta: {
          title: "账户入账",
        },
        component: () => import("@/pages/trading-manage/account/index"),
      },
      {
        path: "/account-settle",
        name: "account-settle",
        meta: {
          title: "账户结算",
        },
        component: () => import("@/pages/trading-manage/account-settle/index"),
      },
      {
        path: "/member",
        name: "member",
        meta: {
          title: "会员管理",
        },
        component: () => import("@/pages/member/index"),
      },
      {
        path: "/member-detail",
        name: "memberDetail",
        hidden: true,
        meta: {
          title: "会员详情",
        },
        component: () => import("@/pages/member/detail"),
      },
    ],
  },

  {
    path: "/customer-service",
    name: "customer-service",
    meta: {
      hidden: false,
      title: "客户服务中心",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      {
        path: "/order",
        name: "order",
        meta: {
          title: "全部订单",
        },
        component: () => import("@/pages/customer-service/order/index"),
      },
      {
        path: "/order-detail",
        name: "order-detail",
        hidden: true,
        meta: {
          title: "订单详情",
        },
        component: () => import("@/pages/customer-service/order/detail"),
      },
      {
        path: "/order-add",
        name: "order-add",
        meta: {
          title: "新增订单",
        },
        component: () => import("@/pages/customer-service/order/add"),
      },
      {
        path: "/order-cake",
        name: "order-cake",
        meta: {
          title: "蛋糕订单",
        },
        component: () => import("@/pages/customer-service/order-cake/index"),
      },
      {
        path: "/other-order",
        name: "otherPlatOrderList",
        meta: {
          title: "第三方订单",
        },
        component: () => import("@/pages/customer-service/order-thirdparty/index"),
      },
      {
        path: "/order-thirdparty-detail",
        name: "otherPlatOrderDetail",
        hidden: true,
        meta: {
          title: "第三方订单详情",
        },
        component: () => import("@/pages/customer-service/order-thirdparty/detail"),
      },
      {
        path: "/orderAudit",
        name: "orderAudit",
        hidden: true,
        meta: {
          title: "订单审核",
        },
        component: () => import("@/pages/customer-service/order-cake/orderAudit"),
      },
      {
        path: "/customer-refund",
        name: "customer-refund",
        meta: {
          title: "售后退款",
        },
        component: () => import("@/pages/customer-service/refund/index"),
      },
    ],
  },

  {
    path: '',
    meta: {
      title: "系统管理",
    },
    component: Layout,
    children:[{
      path: "/personal-center",
      name: "personal-center",
      meta: {
        title: "个人中心",
        icon: "icon-kucun",
      },
      component: () => import("@/pages/system/personal"),
    },{
      path: "/role",
      name: "role",
      meta: {
        title: "角色管理",
      },
      component: () => import("@/pages/system/role"),
    },{
      path: "/staff",
      name: "staff",
      meta: {
        title: "账号管理",
      },
      component: () => import("@/pages/system/staff"),
    },{
      path: "/courier",
      name: "courier",
      meta: {
        title: "配送员账号",
      },
      component: () => import("@/pages/system/courier"),
    },{
      path: "/financial-account",
      name: "financial-account",
      meta: {
        title: "财务账户",
      },
      component: () => import("@/pages/system/account"),
    },{
      path: "/offline-cash-register",
      name: "offline-cash-register",
      meta: {
        title: "收银软件",
      },
      component: () => import("@/pages/system/cashier"),
    }]
  },

  {
    path: 'regional',
    meta: {
      title: "区域管理",
    },
    component: Layout,
    children:[{
      path: "/regional-shop-sell",
      name: "regional-shop-sell",
      meta: {
        title: "城市门店商品",
        icon: "icon-kucun",
      },
      component: () => import("@/pages/regional/sell"),
    },{
      path: "/regional-courier",
      name: "regional-courier",
      meta: {
        title: "城市网点配送员",
      },
      component: () => import("@/pages/system/courier"),
    }],
  },
  {
    path: "/login",
    name: "login",
    hidden: true,
    component: () => import("@/pages/login/index"),
  },
  {
    path: "/shop-order-print",
    name: "shop-order-print",
    hidden: true,
    component: () => import("@/pages/shop/order/order-print"),
  },
];

const router = new Router({
  // mode: "history", //后端支持可开
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (token) {
    if (to.name === "login") {
      next({ path: defaultRoutePath });
    } else {
      // 在免登录名单，直接进入
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next({
        path: loginRoutePath,
        query: { redirect: to.fullPath },
        replace: true,
      });
    }
  }
});
export default router;
